import * as exportedConstants from "../constants/index"

export const truncate = (txt, nb) => {
  if (typeof txt !== "undefined" && txt !== null) {
    txt = txt.replace(/(\r\n|\n|\r)/gm, "")
    if (txt.length > nb) {
      txt = txt.slice(0, nb) + "..."
    }
  }
  return txt
}

export const textsizing = (txt) => {
  if (txt !== null) {
    var textSize
    if (txt.length < 50) {
      textSize = "textMin"
    } else if (txt.length < 100) {
      textSize = "textMin100"
    } else if (txt.length < 200) {
      textSize = "textMin200"
    } else if (txt.length < 300) {
      textSize = "textMin300"
    } else {
      textSize = "more300"
    }
  }
  return textSize
}

export const languageDirectionnality = (txt) => {
  const arabicEncoding = /[\u0600-\u06FF]/
  return arabicEncoding.test(txt) ? "rtl" : "ltr"
}

export const timeFromNow = (timestamp) => {
  const seconds = (Date.now() - Date.parse(timestamp)) / 1000
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${minutes} minutes ago`
}

export const hashtagcolor = (keyword_id) => {
  let colorHashtag
  const { MISKCOLLABORATE_IDS, ASKMISK_IDS, MISKCITY_IDS } = exportedConstants
  switch (true) {
    // case MISKCOLLABORATE_IDS.includes(keyword_id): {
    //   colorHashtag = "miskCollaborate"
    //   break
    // }
    // case ASKMISK_IDS.includes(keyword_id): {
    //   colorHashtag = "askMisk"
    //   break
    // }
    // case MISKCITY_IDS.includes(keyword_id): {
    //   colorHashtag = "miskCity"
    //   break
    // }
    case undefined:
    case null:
    case "":
    default:
      colorHashtag = "default"
      break
  }
  return colorHashtag
}

export const hashtagByKeyword = (keyword_id) => {
  let hashtag
  const { MISKCOLLABORATE_IDS, ASKMISK_IDS, MISKCITY_IDS } = exportedConstants
  switch (true) {
    case MISKCOLLABORATE_IDS.includes(keyword_id): {
      hashtag = "#miskcollaborate"
      break
    }
    case ASKMISK_IDS.includes(keyword_id): {
      hashtag = "#askmisk"
      break
    }
    case MISKCITY_IDS.includes(keyword_id): {
      hashtag = "#miskcity"
      break
    }
    case undefined:
    case null:
    case "":
    default:
      hashtag = ""
      break
  }
  return hashtag
}
