import "./Rectangle.css";
import React, { Component } from "react";
import RectangleVideoTwitter from './RectangleVideoTwitter'
import RectangleImageTwitter from './RectangleImageTwitter'
import RectangleVideoInsta from './RectangleVideoInsta'
import RectangleImageInsta from './RectangleImageInsta'
import { hashtagcolor, hashtagByKeyword } from "../../helpers/index";
import logo from "../../assets/images/twitter.svg";
import logoInsta from "./../../assets/images/instragram.svg";

class Rectangle extends Component {
  constructor(props) {
    super(props);
    this.activeRef = React.createRef();
    this.socialTransitionRef = React.createRef();
    this.oldPostRef = React.createRef();
    this.timeout = null;
    this.state = { post: null };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.active || nextState.post !== null) {
      return true;
    }
    return false;
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.activeRef.current.classList.remove("active")
    if (snapshot !== null) {
      this.timeout = setTimeout(() => {
        snapshot.post.render = true
        this.setState({
          post: snapshot.post,
        })
        this.activeRef.current.classList.add("active");
      }, 3000);
    }
    console.log(this.props.postIndex);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { active, post } = this.props
    if (active && post !== null) {
      let classValue = `${this.socialTransitionRef.current.classList[0]}`
      classValue += ` ${hashtagcolor(post.keyword_id)}`
      this.socialTransitionRef.current.classList.value = classValue
      if(post.source === 'twitter') {
        this.socialTransitionRef.current.children.item(0).style.display = 'block'
        this.socialTransitionRef.current.children.item(1).style.display = 'none'
      } else {
        this.socialTransitionRef.current.children.item(1).style.display = 'block'
        this.socialTransitionRef.current.children.item(0).style.display = 'none'
      }
      return { post: post };
    }
    return null;
  }

  render() {
    const { nameClass, active } = this.props;
    const { post } = this.state;
    
    return (
      <div ref={this.activeRef} className={`rectangle rectangle${nameClass}`}>
        <div className={
            active ? 'content-item-logo' : 'logo-fade-out'
          }>
            <div ref={this.socialTransitionRef} className={`item-logo`}>
              <img className="logo" src={logo} alt="" style={{display: 'none'}}/>
              <img className="logo" src={logoInsta} alt="" style={{display: 'none'}}/>
            </div>
          </div>
        {
        (post !== null && post !== undefined) ? (
          post.source === 'twitter' ? (
            post.media_video_url !== null && post.media_video_url !== undefined ? (
              <RectangleVideoTwitter 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            ) : (
              <RectangleImageTwitter 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            )
          ) : (
            post.media_video_url !== null && post.media_video_url !== undefined ? (
              <RectangleVideoInsta 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            ) : (
              <RectangleImageInsta 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            )
          )
        ) : null
        }
      </div> 
    );
  }
}

export default Rectangle;
