import "./SquareSmall.css";
import React, { Component } from "react";
import SquareTextTwitter from "./SquareTextTwitter";
import SquareImageTwitter from "./SquareImageTwitter";
import SquareImageInstagram from "./SquareImageInstagram";
import { hashtagcolor, hashtagByKeyword } from "../../helpers/index";
import logo from "../../assets/images/twitter.svg";
import logoInsta from "./../../assets/images/instragram.svg";
class SquareSmall extends Component {
  constructor(props) {
    super(props);
    this.activeRef = React.createRef();
    this.socialTransitionRef = React.createRef();
    this.oldPostRef = React.createRef();
    this.timeout = null;
    this.state = { post: null };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.active || nextState.post !== null) {
      return true;
    }
    return false;
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.activeRef.current.classList.remove("active")
    if (snapshot !== null) {
      this.timeout = setTimeout(() => {
        snapshot.post.render = true
        this.setState({
          post: snapshot.post,
        })
        this.activeRef.current.classList.add("active");
      }, 3000);
    }
    console.log(this.props.postIndex);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { active, post } = this.props
    if (active && post !== null) {
      let classValue = `${this.socialTransitionRef.current.classList[0]}`
      classValue += ` ${hashtagcolor(post.keyword_id)}`
      this.socialTransitionRef.current.classList.value = classValue
      // debugger
      if(post.source === 'twitter') {
        this.socialTransitionRef.current.children.item(0).style.display = 'block'
        this.socialTransitionRef.current.children.item(1).style.display = 'none'
      } else {
        this.socialTransitionRef.current.children.item(1).style.display = 'block'
        this.socialTransitionRef.current.children.item(0).style.display = 'none'
      }
      return { post: post };
    }
    return null;
  }

  render() {
    const { nameClass, active } = this.props;
    const { post } = this.state;
    
    return (
      <div ref={this.activeRef} className="square">
        <div className={`small small${nameClass}`}>
          <div className={
            active ? 'item-logo' : ' logo-fade-out'
          }>
            <div ref={this.socialTransitionRef} className={`background-item`}>
              <img className="logo" src={logo} alt="" style={{display: 'none'}}/>
              <img className="logo" src={logoInsta} alt="" style={{display: 'none'}}/>
            </div>
          </div>

          {post !== null && post !== undefined ? (
            post.source === "twitter" ? (
              post.media_file_url !== null &&
              post.media_file_url !== undefined ? (
                <SquareImageTwitter  
                  post={post}  
                  hashtag={hashtagByKeyword(post.keyword_id)}  
                  hashtagColor={hashtagcolor(post.keyword_id)}
                  key={post.id} />
              ) : (
                <SquareTextTwitter 
                  post={post} 
                  hashtag={hashtagByKeyword(post.keyword_id)} 
                  hashtagColor={hashtagcolor(post.keyword_id)}
                  key={post.id} />
              )
            ) : post.media_file_url !== null &&
              post.media_file_url !== undefined ? (
              <SquareImageInstagram 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            ) : null
          ) : null}
        </div>
      </div>
    );
  }
}

export default SquareSmall;
