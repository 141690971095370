import './App.css';
import React, { Component } from 'react';
import Wall from './components/Wall'
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      refresh: false,
      hashtag: null
    }
    this.hashtag = null
  }

  initRefreshTimer = () => {
    this.interval = setInterval(() => {
      this.refreshPage()
    }, 10000 * 3600 * 24)
  }

  refreshPage = () => { window.location.reload(); }

  componentDidMount = () => { this.initRefreshTimer() }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="App">
        <Wall />  
      </div>
    )
  }
}

export default App
