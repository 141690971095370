import { useState, useEffect } from "react";
import logo from "../../assets/images/twitter.svg";
import { timeFromNow } from "../../helpers";

const SquareImageTwitter = (props) => {
  const [post, setPost] = useState({
    "id": null,
    "feed_id": null,
    "id_from_source": null,
    "user_handle": null,
    "user_name": null,
    "profile_image_url": null,
    "text": null,
    "media_file_url": null,
    "source": null,
    "keyword_id": null,
    "media_video_url": null,
    "created_at": null
  })
  let timeout = null

  useEffect(
    () => {
      initPost()
      return () => clearTimeout(timeout)
    },
    []
  );

  const initPost = () => {
    timeout = setTimeout(() => {
      setPost(props.post)
    }, 1000)
  }
  

  return (
    <div>
      {
        post.id !== null &&
        <div className={`in-square-image-twt ${props.old ? 'old' : ''}`}>
          <img
            className="back-image"
            key={post.id}
            src={post.media_file_url ? post.media_file_url : null}
            alt=""
          />

          <img className="logo" src={logo} alt="" />
          <div className="content_image">
            <div className="avatar">
              <img
                className="avatar-img"
                key={post.id}
                src={post.profile_image_url ? post.profile_image_url : null}
                alt=""
              />
            </div>
            <div className="textname"></div>
            <div className="time">{timeFromNow(post.created_at)}</div>
            <div className="hashtag">{props.hashtag}</div>
            <div className={`decoration_div ${props.hashtagColor}`}></div>
          </div>
        </div>
      }
    </div>
  );
};

export default SquareImageTwitter;
