import { useState, useEffect } from "react";
import {
  truncate,
  textsizing,
  languageDirectionnality,
  timeFromNow
} from "../../helpers/index";
import logo from "../../assets/images/twitter.svg";

const SquareVideoTwitter = (props) => {
  const [post, setPost] = useState({
    "id": null,
    "feed_id": null,
    "id_from_source": null,
    "user_handle": null,
    "user_name": null,
    "profile_image_url": null,
    "text": null,
    "media_file_url": null,
    "source": null,
    "keyword_id": null,
    "media_video_url": null,
    "created_at": null
  })
  let timeout = null

  useEffect(
    () => {
      initPost()
      return () => clearTimeout(timeout)
    },
    []
  );
  

  const initPost = () => {
    timeout = setTimeout(() => {
      setPost(props.post)
    }, 1000)
  }

  const textTruncate = truncate(post.text, 280);
  const textSize = textsizing(textTruncate);

  return (
    <div>
      {
        post.id !== null &&
        <div className={`video_twitter ${props.old ? 'old' : ''}`}>
          <div className="bg-video">
            <video className="bg-video__content" autoPlay={true} key={post.id} muted loop>
              <source
                src={post.media_video_url ? post.media_video_url : null}
                type="video/mp4"
              />
            </video>
          </div>

          <div className="video_post">
            <video className="bg-video__content" autoPlay={true} key={post.id} muted loop>
              <source
                src={post.media_video_url ? post.media_video_url : null}
                type="video/mp4"
              />
            </video>
          </div>

          <div className={`text_container ${props.hashtagColor}`}>
            <p
              className={`${textSize} ${languageDirectionnality(
                textTruncate
              )}-layout`}
            >
              {textTruncate ? textTruncate : null}
            </p>
            <img className="logo" src={logo} alt="" />
            <div className={`triangle ${props.hashtagColor}`}></div>
          </div>

          <div className="content_image">
            <div className="avatar">
              <img
                className="avatar-img"
                src={post.profile_image_url ? post.profile_image_url : null}
                alt=""
              />
            </div>
            <div className="textname">
              {post.user_handle ? "@" + post.user_handle : null}
            </div>
            <div className="time">{timeFromNow(post.created_at)}</div>
            <div className="hashtag">{props.hashtag}</div>
            <div className={`decoration_div ${props.hashtagColor}`}></div>
          </div>
        </div>
      }
    </div>
  );
};

export default SquareVideoTwitter;
