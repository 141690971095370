import { useState, useEffect } from "react";
import logoInsta from "./../../assets/images/instragram.svg";
import "./Rectangle.css";
import {
  truncate,
  textsizing,
  languageDirectionnality,
  timeFromNow
} from "../../helpers/index";

const RectangleImageInsta = (props) => {
  const [post, setPost] = useState({
    "id": null,
    "feed_id": null,
    "id_from_source": null,
    "user_handle": null,
    "user_name": null,
    "profile_image_url": null,
    "text": null,
    "media_file_url": null,
    "source": null,
    "keyword_id": null,
    "media_video_url": null,
    "created_at":null
  })
  let timeout = null
  useEffect(
    () => {
      initPost()
      return () => clearTimeout(timeout)
    },
    []
  );

  const initPost = () => {
    // if(props.post.id !== null) {
      timeout = setTimeout(() => {
        setPost(props.post)
      }, 1000)
    // }
  }

  const textTruncate = truncate(post.text, 280);
  const textSize = textsizing(textTruncate);
  

  return (
    <div>
      {
        post.id !== null &&
        <div className="content_image_insta">
          <div className="content_image">
            <img
              className="back-image"
              src={post.media_file_url ? post.media_file_url : null}
              key={post.id}
              alt=""
            />
          </div>
          <div className={`content_text ${props.hashtagColor}`}>
            <p
              className={`${textSize} ${languageDirectionnality(
                textTruncate
              )}-layout`}
            >
              {textTruncate ? textTruncate : null}
            </p>
            <img className="logo" src={logoInsta} alt="" />
          </div>
          <div className="content_information">
            <div className="time">{timeFromNow(post.created_at)}</div>
            <div className="hashtag">{props.hashtag}</div>
            <div className={`decoration_div ${props.hashtagColor}`}></div>
          </div>
        </div>
      }
    </div>
  );
};

export default RectangleImageInsta;
