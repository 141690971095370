import Pusher from "pusher-js"

let pusherInstance

export const initPusher = () => {
  pusherInstance = new Pusher("d5af322caa0f6afa5270", {
    cluster: "mt1",
    authEndpoint: "https://app.the-wallrus.com/pusher/auth",
  })
}

export const subscribeChannel = (id) => {
  return pusherInstance.subscribe("presence-wallrus-" + id)
}


export const getLocalStorage = key => {
  return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

