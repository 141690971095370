import './Wall.css';
import generalWall from "./../assets/images/generalWallLayout.jpg";
import React, { Component } from 'react';
import { API_DOMAIN, EVENT_ID } from '../constants/index';
import Rectangle from './Rectangle/Rectangle';
import RectangleShort from './RectangleShort/RectangleShort';
import RectangleLong from './RectangleLong/RectangleLong';
import SquareSmall from './SquareSmall/SquareSmall'
import SquareBig from './SquareBig/SquareBig'
import { 
  getLocalStorage, 
  setLocalStorage,
} from '../services'

class Wall extends Component {
  constructor(props) {
    super(props);
    this.fetchUrl = `${API_DOMAIN}/events/${EVENT_ID}/cards.json?approved=true&source=all`
    this.initPosts()
    this.fetchInterval = null
    this.numPosts = 150
    this.numComponents = 11
    let index = 0
    this.noTextOnlyComponent = [0, 2, 4, 8]
    this.index = index
    this.state = {
      activeComponent: null,
      post: null
    }
  }

  initPosts = () => {
    setLocalStorage('posts', [])
  }

  initCleanLocalStorage = () => {
    this.interval = setInterval(() => {

    }, 1000 * 60 * 60)
  }

  addPosts = cards => {
    setLocalStorage('posts', cards)
  }

  getStoragePost = postIndex => {
    let post = null
    let posts = getLocalStorage('posts')
    post = posts.length > 0 ? posts[postIndex] : null
    if (post === null || post === undefined ) {
      this.getPosts()
      post = getLocalStorage('posts')
      this.index = 0
      post = posts[this.index]
    } 
    return post
  }

  componentDidMount = () => {
    this.getPosts()
    this.initCleanLocalStorage()
    this.rollComponents()
  }

  componentWillUnmount = () => {
    clearInterval(this.fetchInterval);
  }

  getPosts = () => {
    fetch(this.fetchUrl)
    .then((response) => response.json())
    .then((data) => {
      let cards = this.cleanInstaVideo(data.cards.slice(0, this.numPosts))
      this.addPosts(cards)
    })
    .catch(function(error) {})
  }

  cleanInstaVideo = (cards) => {
    return (
      cards.map((card) => {
        if(card.media_file_url !== null && card.media_file_url.indexOf('video') > -1 
        && card.media_video_url !== null) {
          return {...card, ...{media_file_url: null}}
        } else if (card.media_file_url !== null && card.media_file_url.indexOf('video') > -1  
        && card.media_file_url.indexOf('.mp4') > -1 
        && card.media_video_url === null) {
          return {...card, ...{media_file_url: null}, ...{media_video_url: card.media_file_url}}
        } else {
          return {...card}
        }
      })
    )
  }

  rollComponents = () => {
    setInterval(() => {
      this.changeActiveComponent()
    }, 2 * 1000)
  }

  changeActiveComponent = () => {
    let activeComponent = (this.state.activeComponent === 10 || this.state.activeComponent === null) ? 
    0 : this.state.activeComponent + 1
    if (!this.renewIndex(activeComponent)) {
      
    }
    this.setState((prevState) => ({
      activeComponent: activeComponent,
      post: this.getStoragePost(this.index)
    }))
    activeComponent++
    this.index++
  }

  verifyImageSrc = post => {
    let refreshIndex = false
    if(post.media_file_url !== null) {
      new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(post.media_file_url)
        img.onerror = () => {
          debugger
          refreshIndex = true
        }
      })
    }
    return refreshIndex
  }

  renewIndex = (activeComponent) => {
    let refreshIndex = false
    let post = this.getStoragePost(this.index)
    if (this.noTextOnlyComponent.includes(activeComponent)) {
      while ( this.postHasNoDynamicMedia(post) && this.verifyImageSrc(post) ) {
        refreshIndex = true
        post = this.getStoragePost(this.index)
      }
    }
    return refreshIndex
  }

  postHasNoDynamicMedia = (post) => {
    if(post !== null && post.media_file_url === null && post.media_video_url === null) {
      this.index++
      return true
    }
    return false
  }

  render() {
    let { activeComponent, post } = this.state
    console.log(`ACTIVE COMPONENT IS ${activeComponent}`)
    return ( 
      <div>
        <div className="screen_imagination" style={{ backgroundImage: `url(${generalWall})`}}>
          <Rectangle 
            nameClass={`--first-position`}
            active={activeComponent === 8}
            postIndex={this.index}
            post={activeComponent === 8 && post}
          /> 
          <Rectangle 
            nameClass={`--second-position`}
            active={activeComponent === 2}
            postIndex={this.index}
            post={activeComponent === 2 && post}
          /> 
          <RectangleShort 
            nameClass={`--first-position`}
            active={activeComponent === 10}
            postIndex={this.index}
            post={activeComponent === 10 && post}
          />
          <RectangleLong 
            nameClass={`--first-position`}
            active={activeComponent === 6}
            postIndex={this.index}
            post={activeComponent === 6 && post}
          />
          <SquareSmall 
            nameClass={`--first-position`}
            classInSquare={`in-square-text-twt`}
            active={activeComponent === 5}
            postIndex={this.index}
            post={activeComponent === 5 && post}
          />
          <SquareSmall 
            nameClass={`--second-position`}
            classInSquare={`in-square-image-twt`}
            active={activeComponent === 1}
            postIndex={this.index}
            post={activeComponent === 1 && post}
          />
          <SquareSmall 
            nameClass={`--third-position`}
            classInSquare={`in-square-image-insta`}
            active={activeComponent === 7}
            postIndex={this.index}
            post={activeComponent === 7 && post}
          />
          <SquareSmall 
            nameClass={`--fourth-position`}
            classInSquare={`in-square-image-insta`}
            active={activeComponent === 9}
            postIndex={this.index}
            post={activeComponent === 9 && post}
          />
          <SquareSmall 
            nameClass={`--fifth-position`}
            classInSquare={`in-square-image-insta`}
            active={activeComponent === 3}
            postIndex={this.index}
            post={activeComponent === 3 && post}
          />
          <SquareBig
            nameClass={`--first-position`}
            active={activeComponent === 0}
            postIndex={this.index}
            post={activeComponent === 0 && post}
          />
          <SquareBig
            nameClass={`--second-position`}
            active={activeComponent === 4}
            postIndex={this.index}
            post={activeComponent === 4 && post}
          />
        </div>
      </div>       
    );
  }
}
 
export default Wall;
