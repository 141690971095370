import logo from "../../assets/images/twitter_black.svg";
import { useState, useEffect } from "react";
import { truncate, textsizing, timeFromNow, languageDirectionnality } from '../../helpers/index'

const RectangleImageTwitter = (props) => {
  const [post, setPost] = useState({
    "id": null,
    "feed_id": null,
    "id_from_source": null,
    "user_handle": null,
    "user_name": null,
    "profile_image_url": null,
    "text": null,
    "media_file_url": null,
    "source": null,
    "keyword_id": null,
    "media_video_url": null,
    "created_at": null
  })
  let timeout = null
  useEffect(
    () => {
      initPost()
      return () => clearTimeout(timeout)
    },
    []
  );

  const initPost = () => {
    // if(props.post.id !== null) {
      timeout = setTimeout(() => {
        setPost(props.post)
      }, 1000)
    // }
  }
  const textTruncate = truncate(post.text, 280);
  const textSize = textsizing(textTruncate);
  

  return (
    <div>
      {
        post.id !== null &&
        <div className="content_image_text_twt">
          <div className="bg-image">
            <img 
              className="bg-image__content" 
              key={post.id}
              src={post.media_file_url ? post.media_file_url : null} 
              alt="" />
          </div>
          <div className={`text_content ${props.hashtagColor}`}>
            <p className={`${textSize} ${languageDirectionnality(textTruncate)}-layout`}>
              {textTruncate ? textTruncate : null}
            </p>
          </div>
          <div className={`content_logo ${props.hashtagColor}`}>
            <img className="logo" src={logo} alt="" />
          </div>
          <div className="content_image">
            <img
              className="back-image"
              src={post.media_file_url ? post.media_file_url : null}
              alt=""
            />
          </div>
          <div className="avatar">
            <img
              className="avatar-img"
              key={post.id}
              src={post.profile_image_url ? post.profile_image_url : null}
              alt=""
            />
          </div>
          <div className={`triangle ${props.hashtagColor}`}></div>
          <div className="textname">
            {post.user_name ? "@" + post.user_name : null}
          </div>
          <div className="time">{timeFromNow(post.created_at)}</div>
          <div className="hashtag">{props.hashtag}</div>
          <div className={`decoration_div ${props.hashtagColor}`}></div>
        </div>
      }
    </div>
  );
};

export default RectangleImageTwitter;
