import "./SquareBig.css";
import React, { Component } from "react";
import { hashtagByKeyword, hashtagcolor } from "../../helpers/index";
import logo from "../../assets/images/twitter.svg";
import logoInsta from "./../../assets/images/instragram.svg";
import SquareVideoTwitter from "./SquareVideoTwitter";
import SquareImageTwitter from "./SquareImageTwitter";
import SquareVideoInstagram from "./SquareVideoInstagram";
import SquareImageInstagram from "./SquareImageInstagram";

class Square extends Component {
  constructor(props) {
    super(props);
    this.activeRef = React.createRef();
    this.socialTransitionRef = React.createRef();
    this.oldPostRef = React.createRef();
    this.timeout = null;
    this.state = { post: null };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(nextProps)
    // console.log(this.props)
    // console.log(nextState)
    if (nextProps.active || nextState.post !== null ) {
      return true;
    }
    return false;
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.activeRef.current.classList.remove("active")
    if (snapshot !== null) {
      this.timeout = setTimeout(() => {
        snapshot.post.render = true
        this.setState({
          post: snapshot.post,
        })
        this.activeRef.current.classList.add("active");
      }, 3000);
    }
    console.log(this.props.postIndex);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { active, post } = this.props
    if (active && post !== null) {
      let classValue = `${this.socialTransitionRef.current.classList[0]}`
      classValue += ` ${hashtagcolor(post.keyword_id)}`
      this.socialTransitionRef.current.classList.value = classValue
      if(post.source === 'twitter') {
        this.socialTransitionRef.current.children.item(0).style.display = 'block'
        this.socialTransitionRef.current.children.item(1).style.display = 'none'
      } else {
        this.socialTransitionRef.current.children.item(1).style.display = 'block'
        this.socialTransitionRef.current.children.item(0).style.display = 'none'
      }
      return { post: post };
    }
    return null;
  }

  render() {
    const { nameClass, active } = this.props;
    const { post } = this.state;
    return (
      <div ref={this.activeRef} className="squareBig">
        <div className={`big big${nameClass}`}>
          <div className={
            active ? 'content-item-logo' : ' logo-fade-out'
          }>
            <div ref={this.socialTransitionRef} className={`item-logo`}>
              <img className="logo" src={logo} alt="" style={{display: 'none'}}/>
              <img className="logo" src={logoInsta} alt="" style={{display: 'none'}}/>
            </div>
          </div>

          {post !== null && (
            post.source === "twitter" ? (
              post.media_video_url !== null ? (
                <SquareVideoTwitter 
                  post={post} 
                  hashtag={hashtagByKeyword(post.keyword_id)} 
                  hashtagColor={hashtagcolor(post.keyword_id)}
                  key={post.id} />
              ) : post.media_file_url !== null ? (
                <SquareImageTwitter 
                  post={post} 
                  hashtag={hashtagByKeyword(post.keyword_id)} 
                  hashtagColor={hashtagcolor(post.keyword_id)}
                  key={post.id} />
              ) : null
            ) : post.media_video_url !== null ? (
              <SquareVideoInstagram 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            ) : post.media_file_url !== null ? (
              <SquareImageInstagram 
                post={post} 
                hashtag={hashtagByKeyword(post.keyword_id)} 
                hashtagColor={hashtagcolor(post.keyword_id)}
                key={post.id} />
            ) : null
          )}
        </div>
      </div>
    );
  }
}

export default Square;
