import { useState, useEffect } from "react";
import logoInsta from "./../../assets/images/instragram.svg";
import { hashtagcolor, timeFromNow } from "../../helpers/index";

const SquareImageInstagram = (props) => {
  const [post, setPost] = useState({
    "id": null,
    "feed_id": null,
    "id_from_source": null,
    "user_handle": null,
    "user_name": null,
    "profile_image_url": null,
    "text": null,
    "media_file_url": null,
    "source": null,
    "keyword_id": null,
    "media_video_url": null,
    "created_at": null
  })
  let timeout = null
  useEffect(
    () => {
      initPost()
      return () => clearTimeout(timeout)
    },
    []
  );

  const initPost = () => {
    // if(props.post.id !== null) {
      timeout = setTimeout(() => {
        setPost(props.post)
      }, 1000)
    // }
  }

  return (
    <div>
      {
        post.id !== null &&
        <div className="in-square-image-insta">
          <img
            className="back-image"
            key={post.id}
            src={post.media_file_url ? post.media_file_url : null}
            alt=""
          />
          <img className="logo" src={logoInsta} alt="" />
          <div className="content_image">
            <div className="time">{timeFromNow(post.created_at)}</div>
            <div className="hashtag">{props.hashtag}</div>
            <div className={`decoration_div ${hashtagcolor(post.keyword_id)}`}></div>
          </div>
        </div>
      }
    </div>
  );
};

export default SquareImageInstagram;
